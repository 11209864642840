import { collection, getDocs, query, where, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import toast from "react-hot-toast";

// Function to get all admins by subOwneruid
export const getDrivers = async () => {
    let drivers = []; // Array to hold admin data

    try {
        const db = await getFirestore();
        const auth = getAuth();
        const userId = auth?.currentUser?.uid;

        if (userId === null) {
            return drivers; // Return empty array if no user is logged in
        }

        // Reference to the users collection
        const usersRef = collection(db, "users");

        // Query to get users with the specified subOwneruid and role of admin
        const adminsQuery = query(usersRef, where("subOwneruid", "==", userId), where("role", "==", "driver"));
        const adminsSnapshot = await getDocs(adminsQuery);

        // Loop through the documents and collect admin data
        adminsSnapshot.forEach((doc) => {
            const data = doc.data();
            drivers.push({
                id: doc.id, // Include document ID
                ...data,    // Spread the user data
            });
        });

        // Return the array of admin data
        return drivers;

    } catch (error) {
        toast.error('An error occurred while fetching drivers! Please try again later.');
        console.error("Error fetching drivers:", error);
        return drivers; // Return empty array in case of an error
    }
};
