import React, { useState, useEffect, useRef } from 'react';

export default function CardNotification() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [notifyType, setNotifyType] = useState(''); // Initially empty for placeholder
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const handleSend = () => {
    // Add send logic here
    console.log({
      title,
      description,
      notifyType,
    });
  };

  const toggleDropdown = () => setIsOpen(!isOpen);
  const selectOption = (option) => {
    setNotifyType(option);
    setIsOpen(false);
  };

  // Effect to close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="max-w-lg relative mx-auto bg-white shadow-md rounded-lg p-6">
      {/* Card Header */}
      <h2 className="text-xl font-semibold mb-4 text-gray-700">Send Notification</h2>

      {/* Title Input */}
      <div className="mb-4">
        <label htmlFor="title" className="block text-gray-600 font-medium mb-1">
          Title
        </label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter notification title"
          className="w-full p-2 bg-bluegray-100  border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
      </div>

      {/* Description Input */}
      <div className="mb-4">
        <label htmlFor="description" className="block text-gray-600 font-medium mb-1">
          Description
        </label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter notification description"
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:ring-1"
          rows="4"
        />
      </div>

      {/* Custom Dropdown for Notify Type */}
      <div className="mb-4 relative" ref={dropdownRef}> {/* Attach ref here */}
        <label htmlFor="notifyType" className="block text-gray-600 font-medium mb-1">
          Notify
        </label>
        <div className="relative">
          <button
            onClick={toggleDropdown}
            className="w-full p-2 border bg-blueGray-100 border-gray-300 rounded-lg text-left focus:outline-none focus:ring-1 focus:ring-blue-500 text-gray-600 flex justify-between items-center"
          >
            {notifyType || 'Choose which one to notify...'} {/* Placeholder text */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2" // Icon size
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06 0L10 10.134l3.71-2.924a.75.75 0 111.06 1.061l-4.25 3.25a.75.75 0 01-1.06 0l-4.25-3.25a.75.75 0 010-1.06z" clipRule="evenodd" />
            </svg>
          </button>
          {isOpen && (
            <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-lg mt-1">
              {['All', 'Admins', 'Drivers'].map((option) => (
                <button
                  key={option}
                  onClick={() => selectOption(option)}
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-green-500 hover:text-white"
                >
                  {option}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Send Button */}
      <div className="text-right">
        <button
          onClick={handleSend}
          className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 focus:outline-none focus:ring focus:ring-blue-400"
        >
          Send Notification
        </button>
      </div>
    </div>
  );
}
