/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";

// firebase
import { getAuth, signOut } from "firebase/auth";
import toast from "react-hot-toast";

export default function Sidebar() {
  const auth = getAuth();

  // user data
  const name = auth?.currentUser?.displayName ?? 'Admin';
  const photoUrl = auth?.currentUser?.photoURL ?? require('../../assets/img/user.png');

  const [collapseShow, setCollapseShow] = React.useState("hidden");

  const handleSignout = async () => {
    const promise = signOut(auth);

    toast.promise(promise, {
      loading: 'Signing Out! Please wait...',
      success: 'Signed Out!',
      error: 'Something went wrong while signing out! Please cancel this tab to sign out manually.'
    });
  };


  const showModal = async () => {
    try {

      setCollapseShow('hidden');

      toast.custom((t) => (
        <div className="w-screen h-screen bg-[rgba(0,0,0,0.3)] flex items-center justify-center">
          <div
            className={`${t.visible ? 'animate-enter' : 'animate-leave'
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex flex-col ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 p-4 w-full">
              <div className="flex items-center justify-start w-full">
                <div className="flex-shrink-0">
                  {
                    auth?.currentUser?.photoURL && (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={auth?.currentUser?.photoURL}
                        alt=""
                      />
                    )
                  }
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    Are you sure, you want to signout from MTOS?
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200 border-t">
              <button
                onClick={() => {
                  toast.remove(t.id);
                  handleSignout();
                }}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none"
              >
                Yes Signout!
              </button>
            </div>
            <div className="flex border-l border-gray-200 border">
              <button
                onClick={() => toast.remove(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ))
    } catch (error) {
      toast.error('Something went wrong! try again later.');
    }
  }

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/admin/dashboard"
          >
            {name}
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              {/* User */}
              <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
                onClick={() => navigate('/admin/settings')}
              >
                <img
                  alt="profile"
                  className="w-full h-full rounded-full align-middle border-none shadow-lg object-cover"
                  src={photoUrl}
                />
              </span>
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            {/* <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form> */}

            {/* Divider */}
            {/* <hr className="my-4 md:min-w-full" /> */}

            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/manage_admins") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/manage_admins"
                >
                  <i
                    className={
                      "fas fa-user mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/manage_admins") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Admins
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/drivers") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/drivers"
                >
                  <i
                    className={
                      "fa-solid fa-car-side mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/drivers") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Drivers
                </Link>
              </li>


              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/trips") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/trips"
                >
                  <i
                    className={
                      "fa-solid fa-road mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/trips") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Trips
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/add_trips") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/add_trips"
                >
                  <i
                    className={
                      "fa-solid fa-car mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/add_trips") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Add Trips
                </Link>
              </li>


              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/notify") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/notify"
                >
                  <i
                    className={
                      "fa-solid fa-bell mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/notify") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Notify
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/team_chat") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/team_chat"
                >
                  <i
                    className={
                      "fa-solid fa-message mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/team_chat") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Chat
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/maps") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/maps"
                >
                  <i
                    className={
                      "fas fa-map-marked mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/maps") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Maps
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/settings") !== -1
                      ? "text-green-500 hover:text-green-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/settings"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/settings") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Settings
                </Link>
              </li>

              {/* Divider */}
              <hr className="my-4 md:min-w-full" />

              <li className="items-center">
                <div
                  className={
                    "text-xs uppercase py-3 font-bold block text-red-500 cursor-pointer"
                  }
                  onClick={showModal}
                >
                  <i
                    className={
                      "fas fa-sign-out-alt mr-2 text-sm text-red-500 "
                    }
                  ></i>{" "}
                  Sign Out
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
