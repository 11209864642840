import React from "react";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import TripsTable from "components/Cards/TripsTable";



export default function Trips() {
    return (
      <>
        <div className="flex flex-wrap mt-16">
          <AdminNavbar title={'Trips'} />
          <div className="w-full mb-12 px-4">
           <TripsTable />
          </div>
        </div>
      </>
    );
  }