import { useDrivers } from '../../context/DriversContext.js';
import React, { useState } from "react";
import { FaSearch, FaPrint } from 'react-icons/fa'; // Import Print icon from react-icons
import { ThreeDots } from "react-loader-spinner";

export default function DriversTable() {
  const {
    drivers, loading
  } = useDrivers();

  console.log(drivers);

  const [searchTerm, setSearchTerm] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredDrivers = drivers?.filter((driver) =>
    driver?.fullName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  // Calculate pagination
  const indexOfLastAdmin = currentPage * itemsPerPage;
  const indexOfFirstAdmin = indexOfLastAdmin - itemsPerPage;
  const currentDrivers = filteredDrivers?.slice(indexOfFirstAdmin, indexOfLastAdmin);

  const totalPages = Math.ceil(filteredDrivers?.length / itemsPerPage);

  // Handle printing
  const handlePrint = () => {
    window.print(); // Trigger print dialog
  };

  const renderLoading = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan="11" className="flex flex-row items-center justify-center text-gray-500">
            <ThreeDots
              visible={true}
              height="50"
              width="30"
              color="#4fa94d"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </td>
        </tr>
      );
    } else if (filteredDrivers?.length === 0) {
      return (
        <tr className="text-center">
          {/* <td className="text-center text-gray-500 py-4"> */}
          No records to display!
          {/* </td> */}
        </tr>
      )
    } else {
      return (
        <span>Page {currentPage} of {totalPages}</span>
      )
    }
  };

  return (
    <>
      {/* Container with rounded background */}
      <div className="relative bg-white rounded-lg shadow-lg p-6">
        {/* Top Bar with Search and Print Icon */}
        <div className="flex justify-between items-center mb-4">
          {/* Search Input with Icon */}
          <div className="relative w-1/3">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search by name"
              className="w-full p-2 pl-10 border border-green-200 rounded-2xl bg-blueGray-100 focus:outline-none focus:ring-1"

            />
            <FaSearch className="absolute left-3 top-3 text-gray-500" />
          </div>

          {/* Print Icon Button */}
          <button
            className="bg-blueGray-100 text-blue-500 p-4 rounded-full shadow-lg hover:scale-105 hover:bg-blue-100 focus:outline-none"
            onClick={handlePrint} // Call handlePrint when clicked
          >
            <FaPrint className="w-5 h-5" />
          </button>
        </div>

        {/* Table Container */}
        <div className="overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="bg-green-100">
              <tr className="text-sm text-blueGray-500 font-semibold">
                <th className="px-6 py-3 text-left">IMAGE</th>
                <th className="px-6 py-3 text-left">NAME</th>
                <th className="px-6 py-3 text-left">PHONE</th>
                <th className="px-6 py-3 text-left">EMAIL</th>
                <th className="px-6 py-3 text-left">ADDRESS</th>
                <th className="px-6 py-3 text-left">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {filteredDrivers.map((driver, index) => (
                <tr key={index} className="hover:bg-gray-100 even:bg-gray-50">
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left">
                    <img
                      src={driver?.imageUri}
                      className="h-12 w-12 bg-white rounded-full border"
                      alt="Admin"
                    />
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                    {driver?.fullName ?? 'N/A'}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                    {driver?.phoneNumber ?? 'N/A'}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                    {driver?.email ?? 'N/A'}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                    {driver?.address ?? 'N/A'}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2 text-right">

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center p-4">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            className="bg-green-500 text-white p-2 rounded-xl disabled:opacity-50 focus:outline-none focus:ring-0"
          >
            Previous
          </button>
          {renderLoading()}
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            className="bg-green-500 text-white p-2 rounded-xl disabled:opacity-50 focus:outline-none focus:ring-0"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
