import { useProfile } from '../../context/ProfileContext.js';
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { updateProfile } from '../../firebase/BusinessOwnerAPIs/profile.ts'; // Import the updateProfile function
import toast from 'react-hot-toast';

export default function CardSettings() {
  const { profile, loading } = useProfile();
  const auth = getAuth();
  const userData = auth.currentUser;

  // Initialize form data state
  const [formData, setFormData] = useState({
    fullName: userData?.displayName || "",
    organizationName: profile?.organization || "-",
    email: userData?.email || "",
    phoneNumber: profile?.phoneNumber ?? "N/A",
    image: userData?.photoURL
  });

  // State to track the initial data for comparison
  const [initialData, setInitialData] = useState({
    fullName: userData?.displayName || "",
    organizationName: profile?.organization || "-",
    email: userData?.email || "",
    phoneNumber: profile?.phoneNumber ?? "N/A",
  });

  const [userImageUrl, setUserImageUrl] = useState(userData?.photoURL || "");
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const base64 = event.target.result.split(',')[1]; // Extract base64 data
        setUserImageUrl(reader.result); // Display the image from the URL
        setFormData((prev) => ({
          ...prev,
          image: base64
        }))
      };
      reader.readAsDataURL(file);
    }
  };


  useEffect(() => {
    if (!loading) {
      setFormData((prev) => ({
        ...prev,
        organizationName: profile?.organization || "",
      }));

      // Update the initial data when the profile is loaded
      setInitialData({
        fullName: userData?.displayName || "",
        organizationName: profile?.organization || "-",
        email: userData?.email || "",
        phoneNumber: profile?.phoneNumber ?? "N/A",
      });
    }
  }, [loading, profile]);

  const detectChanges = () => {
    try {
      if (formData?.fullName !== initialData?.fullName) {
        return true;
      } else if (formData?.organizationName !== initialData?.organizationName) {
        return true;
      } else if (formData?.phoneNumber !== initialData?.phoneNumber) {
        return true;
      } else if (userImageUrl !== userData?.photoURL) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return true;
    }
  };

  const isFormValid = () => {
    try {
      if (formData?.fullName === '') {
        toast.error('Full Name is required! Kindly fill it out and then press save button!');
        return false;
      } else if (formData?.organizationName === '') {
        toast.error('Organization Name is your organization identity! Kindly fill it out and then press save button!');
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return true;
    }
  }

  // Function to handle the save button click
  const handleSave = async () => {
    if (!isFormValid()) {
      return;
    }
  
    const updatedData = {
      displayName: formData.fullName,
      organization: formData.organizationName,
      phoneNumber: formData.phoneNumber,
      image: formData.image
    };
  
    // Call the updateProfile API function without showing a loading toast here
    try {
      await updateProfile(updatedData, userData.photoURL);
    } catch (error) {
      console.error("Error in profile update:", error);
    }
  };
  

  useEffect(() => {
    setEnableSaveButton(detectChanges());
  }, [formData, userImageUrl])

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="flex justify-center mb-4">
            <label htmlFor="image-upload" className="cursor-pointer">
              <img
                src={userImageUrl || "https://via.placeholder.com/112"}
                alt="User Account"
                className="rounded-full w-28 h-28 bg-gray-500 object-contain"
              />
            </label>
            <input
              type="file"
              id="image-upload"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </div>
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-semibold">Settings</h6>
            <button
              className={`text-white font-bold uppercase text-xs px-5 py-3 rounded-xl shadow transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-blue-500 ${enableSaveButton
                ? "bg-green-400 hover:shadow-md hover:bg-green-500 cursor-pointer"
                : "bg-green-300 cursor-not-allowed"
                }`}
              type="button"
              onClick={handleSave} // Call handleSave on button click
              disabled={!enableSaveButton}
            >
              Save
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0 bg-green-100">
          <form>
            <h6 className="text-blueGray-600 text-sm mt-3 mb-6 font-bold uppercase">
              Your Information
            </h6>
            <div className="flex flex-wrap">
              {/* Full Name */}
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block lowerCase text-blueGray-500 text-sm font-semibold mb-2" htmlFor="full-name">
                    Full Name *
                  </label>
                  <input
                    type="text"
                    id="full-name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    className="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-blueGray-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                    placeholder={'e.g john Doe'}
                  />
                </div>
              </div>
              {/* Organization Name */}
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block lowerCase text-blueGray-500 text-sm font-semibold mb-2" htmlFor="organization-name">
                    Organization Name *
                  </label>
                  <input
                    type="text"
                    id="organization-name"
                    name="organizationName"
                    value={formData.organizationName}
                    onChange={handleInputChange}
                    className="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-blueGray-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                    placeholder={'e.g Google Inc.'}
                  />
                </div>
              </div>
              {/* Email */}
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block lowerCase text-blueGray-500 text-sm font-semibold mb-2" htmlFor="email">
                    Email *
                  </label>
                  <input
                    type="email"
                    id="email"
                    disabled
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-blueGray-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                    placeholder={'e.g john@gmail.com'}
                  />
                </div>
              </div>
              {/* Phone Number */}
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block lowerCase text-blueGray-500 text-sm font-semibold mb-2" htmlFor="phone-number">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    id="phone-number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-blueGray-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                    placeholder={'e.g 012345678'}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}