import React from 'react'
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import ChatRoom from "../../components/ChatTeam/ChatRoom";

export default function TeamChat() {
  return (
    <>
    <div className="flex flex-wrap mt-16">
      <AdminNavbar title={'Team Chat'} />
      <div className="w-full mb-12 px-4">
       <ChatRoom />
      </div>
    </div>
  </>
  )
}
