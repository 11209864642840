import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoader } from "context/LoaderContext";
import toast from "react-hot-toast";
import { getAuth, sendPasswordResetEmail, } from "firebase/auth";

export default function ForgetPassword() {
  const auth = getAuth();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoader();

  // states
  const [email, setEmail] = useState('');

  // error states
  const [emailError, setEmailError] = useState('');

  const isValidEmail = () => {
    if (email?.length === 0) {
      return false;
    } else if (email?.length < 5) {
      return false;
    } else {
      return true;
    }
  };

  const validateFields = () => {
    let valid = true;

    // Validate email
    if (!isValidEmail()) {
      setEmailError('Invalid Email!')
      valid = false;
    } else {
      setEmailError('');
    }

    return valid;
  };

  const handleForgetPassword = async () => {
    try {
      if (!validateFields()) {
        return;
      }
  
      // Create a promise for the password reset email
      const resetPasswordPromise = sendPasswordResetEmail(auth, email);
  
      // Use toast.promise to handle loading, success, and error states
      toast.promise(
        resetPasswordPromise,
        {
          loading: "Sending password reset email...",
          success: () => {
            navigate("/auth/login");
            return "We have sent a password reset email!";
          },
          error: (error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            switch (errorCode) {
              case "auth/weak-password":
                return "The password is too weak.";
              case "auth/email-already-in-use":
                return "This email address is already in use by another account.";
              case "auth/invalid-email":
                return "This email address is invalid.";
              case "auth/operation-not-allowed":
                return "Email/password accounts are not enabled.";
              default:
                return errorMessage; // Return default error message
            }
          },
        }
      );
  
      showLoader(); // Optional loader management
    } catch (error) {
      // Optional catch block to handle unexpected errors
      console.error("Unexpected error:", error);
    } finally {
      hideLoader(); // Ensure loader is hidden
    }
  };

  useEffect(() => {
    if (isValidEmail(email)) {
      setEmailError('');
    }

  }, [email]);

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Reset Password
                  </h6>
                </div>
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>You will receive a password reset email</small>
                </div>
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label
                      className="block text-red-600 text-xs font-medium mt-1 text-start"
                      htmlFor="email"
                    >
                      {emailError}
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <div>
                      <button
                        onClick={handleForgetPassword}
                        className="bg-primary text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="button"
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-row items-center justify-center mt-2">
                    <p className="text-xs font-semibold">I remember my password! <Link to="/auth/login" className="text-lightBlue-500">
                      Login
                    </Link></p>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
