import React from "react";
import AdminNavbar from '../../components/Navbars/AdminNavbar';
import DriversTable from "components/Cards/DriversTable";
import { DriversProvider } from "../../context/DriversContext.js";

export default function Drivers() {
  return (
    <DriversProvider>
      <div className="flex flex-wrap mt-16">
        <AdminNavbar title={'Drivers'} />
        <div className="w-full mb-12 px-4">
          <DriversTable />
        </div>
      </div>
    </DriversProvider>
  );
}