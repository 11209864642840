import React from "react";
import AdminNavbar from "components/Navbars/AdminNavbar";

// components
import CardSettings from "components/Cards/CardSettings.js";
import { ProfileProvider } from '../../context/ProfileContext.js';

export default function Settings() {
  return (
    <ProfileProvider>
      <div className="flex flex-wrap">
        <AdminNavbar title={'Settings'}/>
        <div className="w-full px-4">
          <CardSettings />
        </div>
      </div>
    </ProfileProvider>
  );
}
