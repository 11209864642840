import React from "react";

// components

import CardStats from "components/Cards/CardStats.js";
import { useContext } from "react";
import { useDashboard } from '../../context/DashboardContext.js';

export default function HeaderStats() {

  const {
    dashboardData
  } = useDashboard();

  return (
    <>
      {/* Header */}
      <div className="mb-6 md:mb-16">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="ADMINS"
                  statTitle={dashboardData?.admins}
                  statArrow="up"
                  statPercent="3.48"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="fa-solid fa-people-group"
                  statIconColor="bg-red-500"
                  route='/admin/manage_admins'
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="DRIVERS"
                  statTitle={dashboardData?.admins}
                  statArrow="down"
                  statPercent="3.48"
                  statPercentColor="text-red-500"
                  statDescripiron="Since last week"
                  statIconName="fa-solid fa-car-side"
                  statIconColor="bg-green-500"
                  route='/admin/Drivers'
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="TRIPS"
                  statTitle={dashboardData?.trips}
                  statArrow="down"
                  statPercent="1.10"
                  statPercentColor="text-orange-500"
                  statDescripiron="Since yesterday"
                  statIconName="fa-solid fa-road"
                  statIconColor="bg-blue-500"
                  route='/admin/Trips'
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="CLIENTS"
                  statTitle={dashboardData?.clients}
                  statArrow="up"
                  statPercent="12"
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="fa-solid fa-person-walking-arrow-loop-left"
                  statIconColor="bg-purple-500"
                  route='/admin/Trips'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
