import React, { useState, useRef, useEffect } from "react";
import { FaSearch, FaPrint, FaFilter, FaChevronDown } from 'react-icons/fa';

export default function TripsTable({ admins = [] }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const dropdownRef = useRef(null); // Ref to track the dropdown

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleDateChange = (e) => {
    setDateRange({ ...dateRange, [e.target.name]: e.target.value });
  };

  const handleFilterClick = () => {
    setShowFilterModal(true);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const filteredAdmins = admins.filter((admin) =>
    admin.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (!statusFilter || admin.status === statusFilter) &&
    (!dateRange.start || new Date(admin.tripDate) >= new Date(dateRange.start)) &&
    (!dateRange.end || new Date(admin.tripDate) <= new Date(dateRange.end))
  );

  const indexOfLastAdmin = currentPage * itemsPerPage;
  const indexOfFirstAdmin = indexOfLastAdmin - itemsPerPage;
  const currentAdmins = filteredAdmins.slice(indexOfFirstAdmin, indexOfLastAdmin);

  const totalPages = Math.ceil(filteredAdmins.length / itemsPerPage);

  const handlePrint = () => {
    window.print();
  };

  // Close dropdown if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowStatusDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <div className="relative bg-white rounded-lg shadow-lg p-6">
        {/* Top Bar with Search, Print, and Filter Icons */}
        <div className="flex justify-between items-center mb-4">
          <div className="relative w-1/3">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search by Client Name"
              className="w-full p-2 pl-10 border border-green-200 rounded-2xl bg-blueGray-100 focus:outline-blue-500 focus:ring-0"
             
            />
            <FaSearch className="absolute left-3 top-3 text-gray-500" />
          </div>

          <div className="flex space-x-4">
            <button
              className="bg-blueGray-100 text-red-500 p-4 rounded-full shadow-lg hover:scale-105 hover:bg-red-200 focus:outline-none"
              onClick={handleFilterClick}
            >
              <FaFilter className="w-5 h-5" />
            </button>

            <button
              className="bg-blueGray-100 text-blue-500 p-4 rounded-full shadow-lg hover:scale-105 hover:bg-blue-200 focus:outline-none"
              onClick={handlePrint}
            >
              <FaPrint className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Table Container */}
        <div className="overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="bg-green-100">
              <tr className="text-sm text-blueGray-500 font-semibold">
                <th className="px-6 py-3 text-left">PU Time</th>
                <th className="px-6 py-3 text-left">ApptTime</th>
                <th className="px-6 py-3 text-left">Client Name</th>
                <th className="px-6 py-3 text-left">Client Mob</th>
                <th className="px-6 py-3 text-left">Client Dis</th>
                <th className="px-6 py-3 text-left">From Address</th>
                <th className="px-6 py-3 text-left">From Phone</th>
                <th className="px-6 py-3 text-left">To Address</th>
                <th className="px-6 py-3 text-left">To Phone</th>
                <th className="px-6 py-3 text-left">Miles</th>
                <th className="px-6 py-3 text-left">Booking Comments</th>
              </tr>
            </thead>
            <tbody>
              {currentAdmins.length > 0 ? (
                currentAdmins.map((admin, index) => (
                  <tr key={index} className="hover:bg-gray-100 even:bg-gray-50">
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left">
                      {admin.puTime}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                      {admin.apptTime}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                      {admin.name}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                      {admin.phone}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                      {admin.clientDis}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                      {admin.fromAddress}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                      {admin.fromPhone}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                      {admin.toAddress}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                      {admin.toPhone}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                      {admin.miles}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                      {admin.bookingComments}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-center text-gray-500 py-8">
                    No records to display!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        {filteredAdmins.length > 0 && (
          <div className="flex justify-between items-center p-4">
            <button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className="bg-green-500 text-white p-2 rounded-xl disabled:opacity-50 focus:outline-none focus:ring-0"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              className="bg-green-500 text-white p-2 rounded-xl disabled:opacity-50 focus:outline-none focus:ring-0"
            >
              Next
            </button>
          </div>
        )}
      </div>

      {/* Filter Modal */}
      {showFilterModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-green-100 p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Filter Trips</h2>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Status</label>
              <div ref={dropdownRef} className="relative">
                <div className="flex justify-between items-center bg-blueGray-100 border border-gray-300 rounded-lg p-2 cursor-pointer" onClick={() => setShowStatusDropdown(!showStatusDropdown)}>
                  <span>{statusFilter || "Select Status"}</span>
                  <FaChevronDown />
                </div>
                {showStatusDropdown && (
                  <div className="absolute left-0 right-0 mt-1 bg-blueGray-50 border border-gray-300 rounded-lg shadow-lg z-10">
                    {["Active", "Pending", "Delayed", "Cancelled", "Completed"].map((status) => (
                      <div
                        key={status}
                        className="p-2 hover:bg-green-500 hover:text-white cursor-pointer"
                        onClick={() => {
                          setStatusFilter(status);
                          setShowStatusDropdown(false);
                        }}
                      >
                        {status}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Date Range</label>
              <div className="flex space-x-4">
                <input
                  type="date"
                  name="start"
                  value={dateRange.start}
                  onChange={handleDateChange}
                  className="border bg-blueGray-100 border-gray-300 p-2 rounded-lg w-full"
                />
                <input
                  type="date"
                  name="end"
                  value={dateRange.end}
                  onChange={handleDateChange}
                  className="border bg-blueGray-100 border-gray-300 p-2 rounded-lg w-full"
                />
              </div>
            </div>

            <div className="flex justify-between">
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg"
                onClick={applyFilters}
              >
                Apply Filters
              </button>
              <button
                className="bg-red-500 text-white hover:bg-red-600 px-4 py-2 rounded-lg"
                onClick={() => setShowFilterModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
