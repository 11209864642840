import React, { createContext, useState, useContext } from 'react';

// Create Context
const LoaderContext = createContext();

// Create Provider Component
export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const showLoader = () => setLoading(true);
    const hideLoader = () => setLoading(false);

    return (
        <LoaderContext.Provider value={{ loading, showLoader, hideLoader }}>
            {children}
        </LoaderContext.Provider>
    );
};

// Custom Hook for using LoaderContext
export const useLoader = () => {
    return useContext(LoaderContext);
};
