import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { setDoc, doc, getFirestore } from "firebase/firestore";
import toast from "react-hot-toast";

export default function Login() {
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  // states
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // error states
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const isValidEmail = () => {
    if (email?.length === 0) {
      return false;
    } else if (email?.length < 5) {
      return false;
    } else {
      return true;
    }
  };

  const isValidPassword = () => {
    if (password?.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const validateFields = () => {
    let valid = true;

    // Validate email
    if (!isValidEmail()) {
      setEmailError('Invalid Email!')
      valid = false;
    } else {
      setEmailError('');
    }

    // Validate password
    if (!isValidPassword()) {
      setPasswordError('Password is required!')
      valid = false;
    } else {
      setPasswordError('');
    }

    return valid;
  };

  const handleLogin = async () => {
    toast.error('We are working on updates! Please try again later.')

    // Validate fields before proceeding
    // if (!validateFields()) {
    //   return;
    // }

    // // Create a promise for the login action
    // const loginPromise = signInWithEmailAndPassword(auth, email, password);

    // // Use toast.promise to handle loading, success, and error states
    // toast.promise(
    //   loginPromise, // The promise to handle
    //   {
    //     loading: "Logging in...", // Loading message
    //     success: (userCredential) => {
    //       // Success message
    //       const user = userCredential.user;
    //       const uid = user.uid;
    //       if (uid) {
    //         navigate("/admin/dashboard"); // Navigate on successful login
    //       }
    //       return "Successfully logged in!"; // Return success message
    //     },
    //     error: (error) => {
    //       setEmail('');
    //       setPassword('');
    //       // Error handling
    //       const errorCode = error.code;
    //       const errorMessage = error.message;
    //       switch (errorCode) {
    //         case "auth/invalid-email":
    //           return "Invalid Credentials!"; // Return error message
    //         case "auth/user-disabled":
    //           return "This email address is disabled by the administrator.";
    //         case "auth/user-not-found":
    //           return "Invalid Credentials!";
    //         case "auth/wrong-password":
    //           return "Invalid Credentials!";
    //         default:
    //           return errorMessage; // Return default error message
    //       }
    //     },
    //   }
    // );
  }

  const googleLogin = async () => {
    toast.error('We are working on updates! Please try again later.')
    // Create a promise for the login action
    // const provider = new GoogleAuthProvider();
    // const loginPromise = signInWithPopup(auth, provider).then(async (userCredential) => {
    //   const user = userCredential.user;
    //   const uid = user.uid;

    //   const userRef = doc(db, "users", uid);

    //   const userData = {
    //     fullName: user.displayName,
    //     email: user.email,
    //     image: user.photoURL,
    //     phone: user.phoneNumber,
    //     role: 'subOwner',
    //   };

    //   // Set or merge the user document
    //   await setDoc(userRef, userData, { merge: true });

    //   return uid; // Return uid for further use
    // });

    // // Use toast.promise to handle loading, success, and error states
    // toast.promise(
    //   loginPromise, // The promise to handle
    //   {
    //     loading: "Logging in with Google...", // Loading message
    //     success: (uid) => {
    //       navigate("/admin/dashboard"); // Navigate on successful login
    //       return "Successfully logged in!"; // Return success message
    //     },
    //     error: (error) => {
    //       // Error handling
    //       const errorCode = error.code;
    //       const errorMessage = error.message;
    //       switch (errorCode) {
    //         case "auth/user-disabled":
    //           return "This email address is disabled by the administrator.";
    //         case "auth/popup-closed-by-user":
    //           return "You cancelled sign in with Google!";
    //         default:
    //           return errorMessage; // Return default error message
    //       }
    //     },
    //   }
    // );

  };

  useEffect(() => {
    if (isValidEmail()) {
      setEmailError('');
    }

    if (isValidPassword()) {
      setPasswordError('');
    }

  }, [email, password]);

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign in with
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                    onClick={googleLogin}
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg").default}
                    />
                    Google
                  </button>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or sign in with credentials</small>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label
                      className="block text-red-600 text-xs font-medium mt-1 text-start"
                      htmlFor="email"
                    >
                      {emailError}
                    </label>
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label
                      className="block text-red-600 text-xs font-medium mt-1 text-start"
                      htmlFor="email"
                    >
                      {passwordError}
                    </label>
                  </div>

                  <Link className="flex flex-row items-center justify-end w-full" to={'/auth/reset-password'}>
                    <p className="text-xs font-semibold text-lightBlue-500 cursor-pointer">Forgot Password?</p>
                  </Link>

                  <div className="text-center mt-6">
                    <div>
                      <button
                        onClick={handleLogin}
                        className="bg-[#40B491] text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="button"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>

                  <div className="flex flex-row items-center justify-center mt-2">
                    <p className="text-xs font-semibold">Doesn't have any account.?    <Link to="/auth/register" className="text-lightBlue-500">
                      Register
                    </Link> to create.</p>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
