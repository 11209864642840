import React from "react";
import ChatBot from "react-chatbotify";

const MTOSChatBot = () => {

    // Define the chatbot flow
    const flow = {
        start: {
            message: "Welcome to MTOS! How can I assist you today?",
            options: ["What is MTOS?", "What is the Owner role?", "What is the Admin role?", "What is the Driver role?", "How do I add an admin?", "How do I manage drivers?", "Pricing Info"],
            path: (params) => {
                switch (params.userInput) {
                    case "What is MTOS?":
                        return "about_mtos";
                    case "What is the Owner role?":
                        return "owner_role";
                    case "What is the Admin role?":
                        return "admin_role";
                    case "What is the Driver role?":
                        return "driver_role";
                    case "How do I add an admin?":
                        return "add_admin";
                    case "How do I manage drivers?":
                        return "manage_drivers";
                    case "Pricing Info":
                        return "pricing";
                    default:
                        return "start";
                }
            }
        },
        about_mtos: {
            message: "MTOS is a Medical Transport OS by SokoGroups. Business owners can manage admins, who in turn manage drivers and routes for efficient transport.",
            options: ["What is the Owner role?", "What is the Admin role?", "What is the Driver role?", "Go back"],
            path: (params) => {
                if (params.userInput === "Go back") return "start";
                return params.userInput === "What is the Owner role?" ? "owner_role" :
                    params.userInput === "What is the Admin role?" ? "admin_role" :
                        "driver_role";
            }
        },
        owner_role: {
            message: "The Owner is the primary account holder with access to all features. They can add admins, send general notifications, and oversee all operations.",
            options: ["What is the Admin role?", "What is the Driver role?", "Go back"],
            path: (params) => {
                if (params.userInput === "Go back") return "start";
                return params.userInput === "What is the Admin role?" ? "admin_role" : "driver_role";
            }
        },
        admin_role: {
            message: "Admins are responsible for managing drivers and routes. They can add new drivers, assign routes, and monitor driver progress and locations.",
            options: ["What is the Driver role?", "Go back"],
            path: (params) => {
                if (params.userInput === "Go back") return "start";
                return "driver_role";
            }
        },
        driver_role: {
            message: "Drivers follow the assigned routes and perform transport tasks. They are monitored by admins for progress and punctuality.",
            options: ["How do I add an admin?", "How do I manage drivers?", "Pricing Info", "Go back"],
            path: (params) => {
                if (params.userInput === "Go back") return "start";
                return params.userInput === "How do I add an admin?" ? "add_admin" :
                    params.userInput === "How do I manage drivers?" ? "manage_drivers" :
                        "pricing";
            }
        },
        add_admin: {
            message: "To add an admin, go to your dashboard, select 'Add Admin' and enter their details. The admin will be notified via email.",
            path: "start"
        },
        manage_drivers: {
            message: "Admins can add drivers by going to their dashboard, selecting 'Add Driver,' and entering the driver's details. Routes will be auto-assigned based on proximity to save fuel.",
            path: "start"
        },
        pricing: {
            message: "The owner account is free. Each admin or driver account costs $100 per month. Notifications and routes are free of charge.",
            path: "start"
        },
        end: {
            message: "Thank you for using MTOS ChatBot! Is there anything else you'd like to ask?",
            options: ["Start Over", "No"],
            path: (params) => (params.userInput === "Start Over" ? "start" : "end")
        }
    };



    return (
        <ChatBot
            styles={{
                footerStyle: {
                    visibility: 'hidden'
                },
                chatHistoryButtonStyle: {
                    visibility: 'hidden'
                },
                headerStyle: {
                    background: '#18A1D8'
                },
                sendButtonStyle: {
                    visibility: 'hidden'
                },
                tooltipStyle: {
                    zIndex:50,
                    background: '#18A1D8'
                }
                
            }}
            flow={flow}
            settings={{
                chatInput: {
                    disabled: true,
                },
                chatHistory: {
                    disabled: true
                },
                header: {
                    title: 'Chat Bot'
                },
                general: {
                    showFooter: false,
                    showInputRow: false,
                },
                tooltip: {
                    mode: "CLOSE",
                    text: "Need Help?",
                },
                chatButton: {
                    icon: require('../../assets/img/chatbot2.png'),
                },
            }}
            
        />
    );
};

export default MTOSChatBot;