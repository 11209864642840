import toast from "react-hot-toast";

export const uploadImage = async (base64) => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

    const apiUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

    try {
        const formData = new FormData();
        formData.append('file', `data:image/jpg;base64,${base64}`);
        formData.append('upload_preset', uploadPreset);

        const options = {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
            },
        };

        const response = await fetch(apiUrl, options);
        const data = await response.json();

        if (data.secure_url) {
            return data.secure_url; // Return the URL of the uploaded image
        } else {
            console.error('Image upload failed. Cloudinary response:', data);
            return '';
        }
    } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
    }
};