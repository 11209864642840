import { useLoader } from "context/LoaderContext";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth, signInWithPopup, GoogleAuthProvider, updateProfile } from "firebase/auth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getFirestore } from "firebase/firestore";

export default function Register() {
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();

  const { showLoader, hideLoader } = useLoader();

  // data states
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // error states
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  // Helper function to validate email format
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Helper function to validate password
  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return passwordRegex.test(password);
  };

  const isValidName = (name) => {
    if (name.length < 3) {
      return false;
    } else {
      return true;
    }
  }

  const validateFields = () => {
    let valid = true;

    // Validate full name (min 3 characters)
    if (!isValidName(name)) {
      setNameError('Full Name must be at least 3 characters.');
      valid = false;
    } else {
      setNameError('');
    }

    // Validate email
    if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    } else {
      setEmailError('');
    }

    // Validate password
    if (!isValidPassword(password)) {
      setPasswordError(
        'Password must be 1-8 characters, include a special character, an uppercase letter, and a number.'
      );
      valid = false;
    } else {
      setPasswordError('');
    }

    // Validate confirm password
    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      valid = false;
    } else {
      setConfirmPasswordError('');
    }

    return valid;
  };

  const handleRegister = async () => {
    toast.error('We are working on updates! Please try again later.')
    // if (!validateFields()) {
    //   return;
    // }

    // const registerPromise = createUserWithEmailAndPassword(auth, email, password)
    //   .then(async (userCredential) => {
    //     const user = userCredential.user;
    //     const uid = user.uid;
    //     const userRef = doc(db, "users", uid);

    //     // Update the user's display name
    //     await updateProfile(user, {
    //       displayName: name
    //     });

    //     const userData = {
    //       fullName: name,
    //       email: email,
    //       role: 'subOwner',
    //     };

    //     // Set the user data in Firestore
    //     await setDoc(userRef, userData, { merge: true });

    //     // Navigate to dashboard after successful account creation
    //     navigate('/admin/dashboard');
    //   });

    // // Use toast.promise to show loading, success, and error states
    // toast.promise(registerPromise, {
    //   loading: 'Creating account...',
    //   success: 'Account created successfully!',
    //   error: (error) => {
    //     const errorCode = error.code;
    //     switch (errorCode) {
    //       case "auth/weak-password":
    //         return "The password is too weak.";
    //       case "auth/email-already-in-use":
    //         return "This email address is already in use by another account.";
    //       case "auth/invalid-email":
    //         return "This email address is invalid.";
    //       case "auth/operation-not-allowed":
    //         return "Email/password accounts are not enabled.";
    //       default:
    //         return error.message;
    //     }
    //   }
    // });
  };

  const googleRegister = async () => {
    toast.error('We are working on updates! Please try again later.')
    // const registerPromise = (async () => {
    //   const provider = new GoogleAuthProvider();
    //   const userCredential = await signInWithPopup(auth, provider);
    //   const user = userCredential.user;

    //   // Check if the user already exists in your Firestore users collection
    //   const userRef = doc(db, "users", user.uid);

    //   const userData = {
    //     fullName: user.displayName,
    //     email: user.email,
    //     image: user.photoURL,
    //     phone: user.phoneNumber,
    //     role: 'subOwner',
    //   };

    //   // Set or merge the user document
    //   await setDoc(userRef, userData, { merge: true });

    //   // Navigate to dashboard after successful registration
    //   navigate('/admin/dashboard');
    // })();

    // // Use toast.promise to handle the promise with toast feedback
    // toast.promise(registerPromise, {
    //   loading: 'Registering with Google...',
    //   success: 'Account created successfully!',
    //   error: 'Something went wrong! Try again later.',
    // });
  };

  useEffect(() => {
    if (isValidName(name)) {
      setNameError('');
    }

    if (isValidEmail(email)) {
      setEmailError('');
    }

    if (isValidPassword(password)) {
      setPasswordError('');
    }

  }, [name, email, password]);

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign up with
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                    onClick={googleRegister}
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg").default}
                    />
                    Google
                  </button>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Or sign up with credentials</small>
                </div>
                <form>
                  {/* Full Name Field */}
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Full Name"
                    />
                    <label
                      className="block text-red-600 text-xs font-medium mt-1 text-start"
                      htmlFor="full-name"
                    >
                      {nameError}
                    </label>
                  </div>

                  {/* Email Field */}
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    />
                    <label
                      className="block text-red-600 text-xs font-medium mt-1 text-start"
                      htmlFor="email"
                    >
                      {emailError}
                    </label>
                  </div>

                  {/* Password Field */}
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                    <label
                      className="block text-red-600 text-xs font-medium mt-1 text-start"
                      htmlFor="password"
                    >
                      {passwordError}
                    </label>
                  </div>

                  {/* Confirm Password Field */}
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="confirm-password"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Confirm Password"
                    />
                    <label
                      className="block text-red-600 text-xs font-medium mt-1 text-start"
                      htmlFor="confirm-password"
                    >
                      {confirmPasswordError}
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-[#40B491] text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleRegister}
                    >
                      Create Account
                    </button>
                  </div>
                  <div className="flex flex-row items-center justify-center mt-2">
                    <p className="text-sm font-semibold">
                      Already have an account?{" "}
                      <Link to="/auth/login" className="text-lightBlue-500">
                        Sign In
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
