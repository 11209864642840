import React, { useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import moment from 'moment'; // For handling time formatting

export default function Chatting() {
  const [messages, setMessages] = useState([
    { id: 1, user: 'John Doe', text: 'Hey, how are you?', profilePic: 'https://via.placeholder.com/50', isSender: true, timestamp: moment().subtract(15, 'minutes') },
    { id: 2, user: 'Jane Smith', text: 'I am good, how about you?', profilePic: 'https://via.placeholder.com/50', isSender: false, timestamp: moment().subtract(26, 'hours') }, // Example for older message
    { id: 3, user: 'John Doe', text: 'Doing great! What’s new?', profilePic: 'https://via.placeholder.com/50', isSender: true, timestamp: moment().subtract(5, 'minutes') },
    { id: 4, user: 'Jane Smith', text: 'Just working on a new project.', profilePic: 'https://via.placeholder.com/50', isSender: false, timestamp: moment().subtract(2, 'minutes') },
  ]);

  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const newMessageObject = {
        id: messages.length + 1,
        user: 'You', // This can be removed later as per your request
        text: newMessage,
        profilePic: 'https://via.placeholder.com/50',
        isSender: true,
        timestamp: moment(), // Add current time for the new message
      };
      setMessages([...messages, newMessageObject]);
      setNewMessage('');
    }
  };

  return (
    <div className="relative rounded-lg flex flex-col h-screen pt-4 bg-green-100">
      {/* Header Section */}
      <div className="flex items-center justify-between px-4 mb-4">
        <h2 className="text-2xl font-semibold text-blueGray-700">
          Team Chats
        </h2>
        <FiSettings className="text-2xl text-gray-600 cursor-pointer hover:text-gray-800" />
      </div>

      <hr className="border-b-2 border-green-500 w-full mx-auto" />

      {/* Chat Area */}
      <div className="flex-1 overflow-y-auto p-4 bg-blueGray-100 shadow-lg rounded-lg mt-2">
        {messages.map((message, index) => {
          const isOlderThan24Hours = moment().diff(message.timestamp, 'hours') >= 24;

          return (
            <div key={message.id}>
              {isOlderThan24Hours && ( // Display day name if message is older than 24 hours
                <div className="flex justify-center mb-2"> {/* Flex container for centering */}
                  <div className="inline-block text-center bg-blueGray-300 text-blueGray-800 p-2 border-2 border-green-300 rounded-lg">
                    {message.timestamp.format('dddd')}
                  </div>
                </div>
              )}
              <div className={`flex items-start mb-4 ${message.isSender ? 'justify-end' : ''}`}>
                {!message.isSender && (
                  <img
                    src={message.profilePic}
                    alt={`${message.user} profile`}
                    className="w-10 h-10 rounded-full mr-3 shadow-md"
                  />
                )}
                <div className="max-w-xs">
                  {/* Display receiver's name for non-sender messages */}
                  {!message.isSender && (
                    <p className={`text-sm font-semibold ${message.isSender ? 'text-right' : ''}`}>
                      {message.user}
                    </p>
                  )}
                  {/* Display message */}
                  <div className={`p-3 rounded-lg ${message.isSender ? 'bg-green-200 text-blueGray-800' : 'bg-gray-200 text-gray-800'}`}>
                    <p className="text-sm">{message.text}</p>
                  </div>
                  {/* Display exact time for messages older than 24 hours */}
                  {isOlderThan24Hours && (
                    <p className="text-xs text-gray-500 mt-1">
                      {message.timestamp.format('HH:mm A')}
                    </p>
                  )}
                  {/* Display time (e.g., '5 mins ago') for recent messages */}
                  {!isOlderThan24Hours && (
                    <p className={`text-xs text-gray-500 mt-1 ${message.isSender ? 'text-right' : ''}`}>
                      {moment(message.timestamp).fromNow()}
                    </p>
                  )}
                </div>
                {message.isSender && (
                  <img
                    src={message.profilePic}
                    alt={`${message.user} profile`}
                    className="w-10 h-10 rounded-full ml-3 shadow-md"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Input Section */}
      <div className="flex items-center rounded-lg border-t p-3 bg-green-100">
        <input
          type="text"
          className="flex-1 p-2 border border-gray-300 rounded-full focus:outline-none focus:ring-1 focus:ring-blue-500 shadow-sm"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button
          onClick={handleSendMessage}
          className="ml-3 bg-green-500 text-white p-2 px-4 rounded-full shadow-lg hover:bg-green-400 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Send
        </button>
      </div>
    </div>
  );
}
