import React, { createContext, useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { getProfile } from '../firebase/BusinessOwnerAPIs/profile.ts';

// Create Context
const ProfileContext = createContext();

// Create Provider Component
export const ProfileProvider = ({ children }) => {
    const [profile, setProfile] = useState({
        email: '',
        fullname: '',
        image: '',
        phone: '',
        organization: ''
    });
    const [loading, setLoading] = useState(true); // State to track loading status

    const fetchProfile = async () => {
        setLoading(true); // Set loading to true before fetching
        try {
            const data = await getProfile();
            setProfile(data);
            
        } catch (err) {
            toast.error('An error occurred while fetching profile! Please try again later.');
            console.error('Error fetching dashboard data:', err);
        } finally {
            setLoading(false); // Set loading to false after fetch
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    return (
        <ProfileContext.Provider value={{ profile, loading }}>
            {children}
        </ProfileContext.Provider>
    );
};

// Custom Hook for using AdminsContext
export const useProfile = () => {
    return useContext(ProfileContext);
};