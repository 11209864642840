import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// components
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views
import Dashboard from "views/admin/Dashboard.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Drivers from "views/admin/Drivers.js";
import Loader from "components/Loader/Loader";
import Trips from "views/admin/Trips";
import Notify from "views/admin/Notify";
import TeamChat from "views/admin/TeamChat";
import { useLoader } from "context/LoaderContext";
import ManageAdmins from "views/admin/ManageAdmins";

export default function Admin() {
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();

  // State to track if auth has been checked
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      // showLoader();
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (!user) {
          navigate("/auth/login");
        } else {
          setAuthChecked(true); // Auth confirmed, render the admin layout
        }
        // hideLoader();
      });

      return unsubscribe;
    };

    checkAuth();
  }, [navigate, showLoader, hideLoader]);

  // Show loader or nothing until auth is checked
  if (!authChecked) {
    return <Loader />; // Render loader while checking auth
  }

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* <AdminNavbar /> */}
        <div className="md:pt-20 pb-52 bg-[#40B491] relative" />
        <div className="px-4 md:px-10 mx-auto w-full -m-40">
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="maps" element={<Maps />} />
            <Route path="settings" element={<Settings />} />
            <Route path="Drivers" element={<Drivers />} />
            <Route path="manage_admins" element={<ManageAdmins />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="Trips" element={<Trips />} />
            <Route path="Notify" element={<Notify />} />
            <Route path="Team_chat" element={<TeamChat />} />



          </Routes>
          <FooterAdmin />
        </div>
        <Loader />
      </div>
    </>
  );
};