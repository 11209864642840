import React, { useState, useRef } from "react"; // Import useRef
import PropTypes from "prop-types";
import { FaSearch } from "react-icons/fa";
import { useAdmins } from "../../context/AdminsContext.js";
import { ThreeDots } from "react-loader-spinner";

export default function CardTable({ color, statIconColor }) {
  const {
    admins, loading
  } = useAdmins();

  const [newAdmin, setNewAdmin] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  const addAdminButtonRef = useRef(null); // Ref for Add Admin button
  const printButtonRef = useRef(null); // Ref for Print button

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin({ ...newAdmin, [name]: value });
  };

  const handleAddAdmin = () => {
    setNewAdmin({ name: "", phone: "", email: "", OrganizationName: "", password: "", address: "" });
    setIsModalOpen(false);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page on new search
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set image preview URL
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };
  const [imagePreview, setImagePreview] = useState(null);

  const filteredAdmins = admins?.filter((admin) =>
    admin?.fullName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  // Calculate pagination
  const indexOfLastAdmin = currentPage * itemsPerPage;
  const indexOfFirstAdmin = indexOfLastAdmin - itemsPerPage;
  const currentAdmins = filteredAdmins?.slice(indexOfFirstAdmin, indexOfLastAdmin);

  const totalPages = Math?.ceil(filteredAdmins?.length / itemsPerPage === 0 ? 1 : filteredAdmins?.length / itemsPerPage);

  // Button styles
  const buttonStyle = {
    transition: "background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease",
    backgroundColor: "white", // default background color
  };

  const [isAddHovered, setIsAddHovered] = useState(false);
  const [isPrintHovered, setIsPrintHovered] = useState(false);

  const renderLoading = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan="11" className="flex flex-row items-center justify-center text-gray-500">
            <ThreeDots
              visible={true}
              height="50"
              width="30"
              color="#4fa94d"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </td>
        </tr>
      );
    } else if (filteredAdmins?.length === 0) {
      return (
        <tr className="text-center">
          {/* <td className="text-center text-gray-500 py-4"> */}
          No records to display!
          {/* </td> */}
        </tr>
      )
    } else {
      return (
        <span>Page {currentPage} of {totalPages}</span>
      )
    }
  }

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-white ")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0  bg-white">
          <div className="flex flex-wrap items-center justify-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <div className="flex justify-between items-center">
                {/* <h3
                  className="font-semibold text-xl text-blueGray-700"
                >
                  Admins
                </h3> */}
                <div className="relative w-1/3">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search by name"
                    className="w-full p-2 pl-10 border border-green-200 rounded-2xl bg-blueGray-100 focus:outline-none focus:ring-1"

                  />
                  <FaSearch className="absolute left-3 top-3 text-gray-500" />
                </div>
                <div className="flex flex-row items-center justify-end gap-4">
                  <button
                    ref={addAdminButtonRef} // Attach ref for Add Admin button
                    onClick={() => setIsModalOpen(true)} // Open modal on click
                    onMouseEnter={() => setIsAddHovered(true)}
                    onMouseLeave={() => setIsAddHovered(false)}
                    style={{
                      ...buttonStyle,
                      backgroundColor: isAddHovered ? "rgba(0, 255, 0, 0.1)" : "rgb(236,236,236)", // Change background on hover
                      transform: isAddHovered ? "scale(1.05)" : "scale(1)", // Slightly scale up the button
                      boxShadow: isAddHovered ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "shadow-lg", // Add shadow on hover
                    }}
                    className={
                      "p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full focus:outline-none focus:ring" +
                      (statIconColor ? statIconColor : "bg-gray-200")
                    }
                  >
                    <span><i className="fa-solid fa-user-plus text-green-500"></i></span>
                  </button>
                  <button
                    ref={printButtonRef} // Attach ref for Print button
                    onClick={handlePrint}
                    onMouseEnter={() => setIsPrintHovered(true)}
                    onMouseLeave={() => setIsPrintHovered(false)}
                    style={{
                      ...buttonStyle,
                      backgroundColor: isPrintHovered ? "rgba(0, 0, 255, 0.1)" : "rgb(236,236,236)", // Change background on hover
                      transform: isPrintHovered ? "scale(1.05)" : "scale(1)", // Slightly scale up the button
                      boxShadow: isPrintHovered ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "shadow-lg", // Add shadow on hover
                    }}
                    className="p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full no-print focus:outline-none focus:ring-0"
                  >
                    <i className="fa fa-print text-blue-500"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="bg-green-100 opacity-0.8">
              <tr className="text-sm text-blueGray-500 font-semibold">
                <th className="px-6 py-3 text-left">IMAGE</th>
                <th className="px-6 py-3 text-left">NAME</th>
                <th className="px-6 py-3 text-left">PHONE</th>
                <th className="px-6 py-3 text-left">EMAIL</th>
                <th className="px-6 py-3 text-left">ADDRESS</th>
                <th className="px-6 py-3 text-right">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {currentAdmins?.map((admin, index) => (
                <tr key={index} className="hover:bg-gray-100 even:bg-gray-50">
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-2 text-left">
                    <img
                      src={admin?.imageUrl}
                      className="h-12 w-12 bg-white rounded-full border"
                      alt="Admin"
                    />
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                    {admin?.fullName ?? 'N/A'}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                    {admin?.phoneNumber ?? 'N/A'}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                    {admin?.email ?? 'N/A'}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                    {admin?.address ?? 'N/A'}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2 text-right">

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Pagination Controls */}
        <div className="flex justify-between items-center p-4">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            className="bg-green-500 text-white p-2 rounded-xl disabled:opacity-50 focus:outline-none
            focus:ring-0"
          >
            Previous
          </button>
          {renderLoading()}
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            className="bg-green-500 text-white p-2 rounded-xl disabled:opacity-50 focus:outline-none
            focus:ring-0"
          >
            Next
          </button>
        </div>
      </div>
      {/* Modal for Adding Admin */}

      {isModalOpen && (
        <>
          {/* Overlay for darkening the background */}
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>

          {/* Modal for Adding Admin */}
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-green-100 p-6 rounded-xl shadow-lg w-full max-w-md">
              {/* Image preview */}
              <div className="mb-4">
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Image"
                    className="w-32 h-32 object-cover rounded-full mx-auto cursor-pointer"
                    onClick={() => document.getElementById("imageInput").click()} // Trigger file input on click
                  />
                ) : (
                  <div
                    className="w-32 h-32 bg-gray-200 flex items-center justify-center rounded-full mx-auto cursor-pointer"
                    onClick={() => document.getElementById("imageInput").click()} // Trigger file input on click
                  >
                    <span className="text-gray-500">Upload Image</span>
                  </div>
                )}
              </div>

              {/* Hidden file input */}
              <input
                type="file"
                id="imageInput"
                accept="image/*"
                className="hidden"
                onChange={handleImageUpload} // Handle image upload
              />

              {/* Other form fields */}
              <h3 className="text-xl font-semibold mb-4">Add New Admin</h3>
              <input
                type="text"
                name="name"
                value={newAdmin.name}
                onChange={handleInputChange}
                placeholder="Name"
                className="border p-2 w-full mb-2 rounded-lg"
              />
              <input
                type="text"
                name="organizationName"
                value={newAdmin.OrganizationName}
                onChange={handleInputChange}
                placeholder="Organization Name"
                className="border p-2 w-full mb-2 rounded-lg"
              />
              <input
                type="text"
                name="phone"
                value={newAdmin.phone}
                onChange={handleInputChange}
                placeholder="Phone Number"
                className="border p-2 w-full mb-2 rounded-lg"
              />
              <input
                type="text"
                name="email"
                value={newAdmin.email}
                onChange={handleInputChange}
                placeholder="Email"
                className="border p-2 w-full mb-2 rounded-lg"
              />
              <input
                type="password"
                name="password"
                value={newAdmin.password}
                onChange={handleInputChange}
                placeholder="Password"
                className="border p-2 w-full mb-2 rounded-lg"
              />
              <input
                type="text"
                name="address"
                value={newAdmin.address}
                onChange={handleInputChange}
                placeholder="Address"
                className="border p-2 w-full mb-4 rounded-lg"
              />
              <div className="flex justify-end items-center">
                <button
                  onClick={handleAddAdmin}
                  className="bg-green-500 text-white p-2 rounded-xl transition-opacity duration-200 hover:opacity-70"
                >
                  Add Admin
                </button>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="bg-red-500 text-white p-2 rounded-xl ml-2 transition-opacity duration-200 hover:opacity-70"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

}

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
  statIconName: PropTypes.string,
  statIconColor: PropTypes.string,
};


