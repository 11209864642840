import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useLoader } from 'context/LoaderContext';

const Loader = () => {
    const { loading } = useLoader();

    if (!loading) {
        return null;
    }

    return (
        <div
            className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(0,0,0,0.3)] z-50'
        >
            <TailSpin
                visible={true}
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    );
};

export default Loader;