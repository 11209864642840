import { getAuth, updateProfile as updateProfileFromAuth } from "firebase/auth";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { uploadImage } from '../UploadImage.js';
import toast from "react-hot-toast";

// Function to get a user by ID
export const getProfile = async () => {
    let userData = null; // Variable to hold user data

    const auth = getAuth();
    const userId = auth?.currentUser?.uid;

    try {
        const db = await getFirestore();

        // Reference to the specific user document by ID
        const userRef = doc(db, "users", userId);

        // Get the document
        const userSnapshot = await getDoc(userRef);

        // Check if the document exists
        if (userSnapshot.exists()) {
            userData = { id: userSnapshot.id, ...userSnapshot.data() }; // Include document ID
        }

        // Return the user data
        return userData;

    } catch (error) {
        console.error("Error fetching user by ID:", error);
        toast.error('Something went wrong! Please try again later!')
        return userData; // Return null in case of an error
    }
};

// Function to update user profile
export const updateProfile = async (updatedData: any, oldPhoto: string) => {
    const auth = getAuth();
    const userId = auth.currentUser?.uid;

    if (!userId) {
        toast.error('Session expired. Please log in again.');
        return;
    }

    // Show a loading toast while the update is in progress
    const loadingToast = toast.loading("Updating your profile...");

    try {
        const imageUrl = await uploadImage(updatedData?.image);
        if (imageUrl) {
            updatedData.image = imageUrl;
        } else {
            toast.loading('Profile picture cannot be updated! Updating other fields!', {
                id: loadingToast, // Update the specific toast by ID
            });
            updatedData.image = oldPhoto;
        }

        const db = getFirestore();
        const userRef = doc(db, "users", userId);

        // Update the user document with new data
        await updateDoc(userRef, updatedData);
        await updateProfileFromAuth(auth.currentUser, {
            displayName: updatedData.fullName,
            photoURL: imageUrl ?? oldPhoto
        })

        // If successful, update the toast to success
        toast.success("Profile updated successfully!", {
            id: loadingToast, // Update the specific toast by ID
        });
    } catch (error) {
        console.error("Error updating profile:", error);
        toast.error('An error occurred while updating the profile. Please try again later.', {
            id: loadingToast, // Update the specific toast by ID
        });
    }
};

