import React, { createContext, useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { getDrivers } from '../firebase/BusinessOwnerAPIs/drivers.ts';

// Create Context
const DriversContext = createContext();

// Create Provider Component
export const DriversProvider = ({ children }) => {
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(true); // State to manage loading

    const fetchDrivers = async () => {
        setLoading(true); // Set loading to true before fetching
        try {
            const data = await getDrivers();                        
            setDrivers(data);
        } catch (err) {
            toast.error('An error occurred while fetching drivers! Please try again later.');
            console.error('Error fetching dashboard data:', err);
            setDrivers([]);
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    useEffect(() => {
        fetchDrivers();
    }, []);

    return (
        <DriversContext.Provider value={{ drivers, loading }}> {/* Include loading in the context value */}
            {children}
        </DriversContext.Provider>
    );
};

// Custom Hook for using DriversContext
export const useDrivers = () => {
    return useContext(DriversContext);
};