import React, { createContext, useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { getAdmins } from '../firebase/BusinessOwnerAPIs/admins.ts';

// Create Context
const AdminsContext = createContext();

// Create Provider Component
export const AdminsProvider = ({ children }) => {
    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(true); // State to track loading status

    const fetchAdmins = async () => {
        setLoading(true); // Set loading to true before fetching
        try {
            const data = await getAdmins();
            setAdmins(data);
        } catch (err) {
            toast.error('An error occurred while fetching admins! Please try again later.');
            console.error('Error fetching dashboard data:', err);
            setAdmins([]); // Optionally reset admins
        } finally {
            setLoading(false); // Set loading to false after fetch
        }
    };

    useEffect(() => {
        fetchAdmins();
    }, []);

    return (
        <AdminsContext.Provider value={{ admins, loading }}>
            {children}
        </AdminsContext.Provider>
    );
};

// Custom Hook for using AdminsContext
export const useAdmins = () => {
    return useContext(AdminsContext);
};