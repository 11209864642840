import React from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router";

export default function Navbar({ title }) {
  const navigate = useNavigate();

  const auth = getAuth();

  const photoUrl = auth?.currentUser?.photoURL ?? require('../../assets/img/user.png');

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            {title}
          </a>
          {/* Form */}
          {/* <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
            <div className="relative flex w-full flex-wrap items-stretch">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-search"></i>
              </span>
              <input
                type="text"
                placeholder="Search here..."
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
              />
            </div>
          </form> */}
          {/* User */}
          <span className="w-12 cursor-pointer hidden md:flex h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
          onClick={()=> navigate('/admin/settings')}
          >
            <img
              alt="profile"
              className="w-full h-full rounded-full align-middle border-none shadow-lg object-cover"
              src={photoUrl}
            />
          </span>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
