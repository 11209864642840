import React from "react";

// components
import AdminNavbar from '../../components/Navbars/AdminNavbar';
import CardTable from "components/Cards/CardTable.js";
import { AdminsProvider } from "context/AdminsContext.js";

export default function ManageAdmins() {
  return (
    <AdminsProvider>
      <div className="flex flex-wrap mt-4">
        <AdminNavbar title={'Manage Admins'} />
        <div className="w-full mb-12 px-4">
          <CardTable />
        </div>
      </div>
    </AdminsProvider>
  );
}
