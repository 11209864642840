import React from "react";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import Notify from "../../components/Cards/CardNotification";




export default function Trips() {
    return (
      <>
        <div className="flex flex-wrap mt-16">
          <AdminNavbar title={'Notify'} />
          <div className="w-full mb-12 px-4">
         <Notify />
          </div>
        </div>
      </>
    );
  }