import { collection, getDocs, query, where, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import toast from "react-hot-toast";

// Function to count total drivers, admins, unique clients, and trips by subOwneruid
export const getDashAnalytics = async () => {
    let counts = {
        admins: 0,
        drivers: 0,
        clients: 0,
        trips: 0,
    };

    try {
        const db = await getFirestore();
        const auth = getAuth();
        const userId = auth?.currentUser?.uid;

        if (userId === null) {
            return counts; // Return counts as zero if no user is logged in
        }

        // Reference to the users collection
        const usersRef = collection(db, "users");

        // Query to get users with the specified subOwneruid
        const usersQuery = query(usersRef, where("subOwneruid", "==", userId));
        const usersSnapshot = await getDocs(usersQuery);

        // Loop through the users to count admins and drivers
        usersSnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.role === "admin") {
                counts.admins++;
            } else if (data.role === "driver") {
                counts.drivers++;
            }
        });

        // Reference to the trips collection
        const tripsRef = collection(db, "trips");

        // Query to get trips with the specified subOwneruid
        const tripsQuery = query(tripsRef, where("subOwneruid", "==", userId));
        const tripsSnapshot = await getDocs(tripsQuery);

        // Initialize a Set to track unique clients
        const uniqueClients = new Set();

        // Loop through the trips to count total trips and unique clients
        tripsSnapshot.forEach((doc) => {
            const data = doc.data();
            // Increment the totalTrips count
            counts.trips++;

            // Add the clientUid to the Set to ensure uniqueness
            if (data.clientUid) {
                uniqueClients.add(data.clientUid);
            }
        });

        // Set the total unique clients
        counts.clients = uniqueClients.size;

        // Return the combined counts
        return counts;

    } catch (error) {
        toast.error('An error occurred while fetching dashboard data! Please try again later.');
        return counts; // Return the counts in case of an error
    }
};
