import React, { createContext, useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { getDashAnalytics } from '../firebase/BusinessOwnerAPIs/dashboard.ts';

// Create Context
const DashboardContext = createContext(); 

// Create Provider Component
export const DashboardProvider = ({ children }) => {
    const [dashboardData, setDashboardData] = useState({
        admins: '-',
        drivers: '-',
        trips: '-',
        clients: '-',
    });

    const fetchDashboardData = async () => {
        try {
            const data = await getDashAnalytics();
            setDashboardData(data);
        } catch (err) {
            toast.error('An error occurred while fetching dashboard data! Please try again later.');
            console.error('Error fetching dashboard data:', err);
            setDashboardData({
                admins: 0,
                drivers: 0,
                trips: 0,
                clients: 0,
            });
        }
    };


    useEffect(() => {
        fetchDashboardData();
    }, []);

    return (
        <DashboardContext.Provider value={{ dashboardData }}>
            {children}
        </DashboardContext.Provider>
    );
};

// Custom Hook for using DashboardContext
export const useDashboard = () => {
    return useContext(DashboardContext);
};